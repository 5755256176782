import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function DestinationAirport(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus =='flightNo') {
        inputRef.current.focus();
    }
    
    function destinationEntered(value) {
        if (value != ''){
            setCurrentFocus('destinationAirportIataCode_'+index)
            messageValues['flightDetailLines'][index]['destinationAirportIataCode'] = value
            setMessageValues(messageValues)
            updateParent()
        }
    }

    let hasFocus = currentFocus === 'destinationAirportIataCode_'+index ? true : false

    return (
        <>
            <p className="text-muted mb-0 small">Destination</p>
            <Form.Control 
                key={Math.random()} 
                aria-label="Destination" 
                type="text" 
                defaultValue={messageValues['flightDetailLines'][index]['destinationAirportIataCode']} 
                onChange={e => { destinationEntered(e.target.value) }} 
                onClick={() => { setCurrentFocus('destinationAirportIataCode_'+index) }}
                autoFocus={hasFocus}
                ref={inputRef}

                />
        </>
    );
}

export default DestinationAirport