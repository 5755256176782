import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleCheck, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";

function Validate(props) {
    let line = props.line
    const header = props.header
    console.log('header', header)
    console.log('line', line)

    let invalid = <FontAwesomeIcon icon={faCircleExclamation} className="me-1 text-danger" />
    let unvalidated = <FontAwesomeIcon icon={faCircleExclamation} className="me-1 text-warning" />
    let valid = <FontAwesomeIcon icon={faCircleCheck} className="me-1 text-success" />

    if (line.showRawInput) {
        return unvalidated
    }

    if (!line.actionCode) {
        console.log('Validate: No action code')
        return invalid
    }


    if (header.typeOfRequest == 'SIR'){
        if (line.type == 'arrival' || line.type == 'departure'){
            if (!line.periodStart || line.periodStart == '') {
                return invalid
            }
        }
        if (line.type == 'turn'){
            if (!line.turnDate || line.turnDate == '') {
                return invalid
            }
            if (!line.arrAirline || line.arrAirline == '') {
                return invalid
            }
            if (!line.depAirline || line.depAirline == '') {
                return invalid
            }
        }
        return valid
    }

    if (!line.aircraftSeats) {
        console.log('Validate: No seats')
        return invalid
    }


    if (line.type == 'turn') {
        console.log(line)
        if (!line.arrAirline){
            console.log('Validate: No arrAirline')
            return  invalid
        }
        if (![0,1,"0","1"].includes(line.Oval)){
            console.log('Validate: Not valid line.Oval')
            return  invalid
        }
        if (!line.depAirline){
            console.log('Validate: No depAirline')
            return  invalid
        }
        if (!line.depFlightNo){
            console.log('Validate: No depFlightNo')
            return  invalid
        }
        if (!line.iataAircraftCode){
            console.log('Validate: No iataAircraftCode')
            return  invalid
        }
        if (!line.originAirportIataCode){
            console.log('Validate: No originAirportIataCode')
            return  invalid
        }
        if (!line.destinationAirportIataCode){
            console.log('Validate: No destinationAirportIataCode')
            return  invalid
        }
        if (!line.arrTypeOfFlight){
            console.log('Validate: No arrTypeOfFlight')
            return  invalid
        }
        if (!line.depTypeOfFlight){
            console.log('Validate: No depTypeOfFlight')
            return  invalid
        }
        if (!line.departureTimeUTC){
            console.log('Validate: No departureTimeUTC')
            return  invalid
        }
        if (!line.arrivalTimeUTC){
            console.log('Validate: No arrivalTimeUTC')
            return  invalid
        }
        if (line.turnDateType =='range'){
            let uniqueWeekdays = [... new Set(line.weekdays)]
            if (uniqueWeekdays.length == 1) {
                console.log('Validate: No weekdays not set')
                return invalid
            }
        }


        console.log('All good',line)
        return valid

    }

    if (!line.airline) {

        if (line.type == 'turn') {
            if (!line.depAirline || !line.arrAirline) {
                console.log('Validate: No airline for turn')
                return invalid
            }
        }

        if (line.type != 'turn') {
            console.log('line.type', line.type)
            console.log('Validate: No type')
            return invalid
        }
    }

    if (!line.flightNo) {
        if (line.type != 'turn') {
            console.log('Validate: No flightNo')
            return invalid
        }
        if (line.type == 'turn') {
            if (!line.arrFlightNo || !line.depFlightNo) {
                console.log('Validate: arrFlightNo or depFlightNo missing')
                return invalid
            }

        }

    }

    if (!line.periodStart || line.periodStart == '') {
        if (line.type != 'turn') {
            console.log('Validate: No periodStart', line.periodStart)
            return invalid
        }
    }
    if (line.type == 'arrival') {
        if (!line.arrivalTimeUTC || line.arrivalTimeUTC == '') {
            console.log('Validate: No arrivalTimeUTC')
            return invalid
        }
    }
    if (line.type == 'departure') {
        if (!line.departureTimeUTC || line.departureTimeUTC == '') {
            console.log('Validate: No departureTimeUTC')
            return invalid
        }
    }
    if (!line.iataAircraftCode) {
        console.log('Validate: No iataAircraftCode')
        return invalid
    }
    if (line.type == 'arrival') {
        if (!line.originAirportIataCode) {
            console.log('Validate: No originAirportIataCode')
            return invalid
        }
    }
    if (line.type == 'departure') {
        if (!line.destinationAirportIataCode) {
            console.log('Validate: No destinationAirportIataCode')
            return invalid
        }
    }
    if (!line.typeOfFlight) {
        console.log('Validate: No typeOfFlight')
        return invalid
    }
    if (line.showDateSpan) {
        console.log('Validate: showDateSpan is true', line.weekdays == [0, 0, 0, 0, 0, 0, 0], line.weekdays)
        let uniqueWeekdays = [... new Set(line.weekdays)]
        if (uniqueWeekdays.length == 1) {
            console.log('Validate: No weekdays not set')
            return invalid
        }
    }
    return valid

}

export default Validate
