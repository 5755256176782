import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function TurnAirlineCode(props) {
    let index = props.index
    let dep_arr = props.dep_arr
    let messageValues = props.messageValues
    let setMessageValues = props.setMessageValues
    let updateParent = props.updateParent
    let tabIndex = 0
    let setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (props.tabIndex) { tabIndex = props.tabIndex }

    function airlineSelected(value) {
        let newMessageValues = messageValues
        newMessageValues['flightDetailLines'][index][`${dep_arr}Airline`] = value
        setMessageValues(newMessageValues)
        updateParent()
        setCurrentFocus(`${dep_arr}Airline_` + index)
    }

    let labelText = 'Arr'
    if (dep_arr == 'dep') {
        labelText = 'Dep'
    }

    let hasFocus = currentFocus === `${dep_arr}Airline_` + index ? true : false

    return (
        <>
            <p className="text-muted mb-0 small">Operator {labelText}</p>
            <Form.Select
                aria-label="Airline Codes"
                value={messageValues['flightDetailLines'][index][`${dep_arr}Airline`]}
                onChange={e => { airlineSelected(e.target.value) }}
                className="float-start"
                tabIndex={tabIndex}
                autoFocus={hasFocus}
            >
                <option></option>
                <option value="TOM">TOM</option>
                <option value="BLX">BLX</option>
                <option value="X3">X3</option>
                <option value="OR">OR</option>
                <option value="TB">TB</option>
                <option value="BY">BY</option>
            </Form.Select>
        </>
    );
}

export default TurnAirlineCode