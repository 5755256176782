import Header from "./components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';

function App() {
  return (
    <Header />
    );

}

export default App;
