import React, {
    useEffect,
    useState,
} from "react";
import Form from 'react-bootstrap/Form';

function Footnotes(props) {

    // console.log(props)
    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const updateParent = props.updateParent
    const currentFocus = props.currentFocus
    const setCurrentFocus = props.setCurrentFocus

    function SIentered(e) {
        let textValue = e.target.value
        let newMessageValues = messageValues
        newMessageValues['footer']['SI'] = textValue
        setMessageValues(newMessageValues)
        console.log(e.target.value)
        updateParent()
        setCurrentFocus('supInfo')

    }

    function GIentered(e) {
        let textValue = e.target.value
        let newMessageValues = messageValues
        newMessageValues['footer']['GI'] = textValue
        setMessageValues(newMessageValues)
        // console.log(e.target.value)
        updateParent()
        setCurrentFocus('genInfo')

    }

    let genFocus = currentFocus === 'genInfo' ? true : false
    let supFocus = currentFocus === 'supInfo' ? true : false

    return <>
        <div className="col-lg-12 my-auto">
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Supplementary Information</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        maxLength={500}
                        onChange={e => { SIentered(e) }}
                        autoFocus={supFocus}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>General Information</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        maxLength={500}
                        onChange={e => { GIentered(e) }}
                        autoFocus={genFocus}
                    />
                </Form.Group>
            </Form>
        </div>
    </>

}

export default Footnotes