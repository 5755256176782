import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function Oval(props) {
    let index = props.index
    let messageValues = props.messageValues
    let setMessageValues = props.setMessageValues
    let updateParent = props.updateParent
    let currentFocus = props.currentFocus
    const setCurrentFocus = props.setCurrentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus == `Oval_${index}`) {
        inputRef.current.focus();
    }

    function O_val_entered(value) {
            if (![0,1,'','0','1'].includes(value)){
                messageValues['flightDetailLines'][index][`Oval`] = ''
            }else{
                messageValues['flightDetailLines'][index][`Oval`] = value
            }
            setCurrentFocus(`Oval_${index}`)
            setMessageValues(messageValues)
            updateParent()
    }

    let hasFocus = currentFocus === `Oval_${index}` ? true : false

    return (
        <>
            <p className="text-muted mb-0 small">OI</p>
            <Form.Control
                key={Math.random}
                aria-label=""
                type="number"
                maxLength={1}
                defaultValue={messageValues['flightDetailLines'][index]['Oval']}
                onChange={e => { O_val_entered(e.target.value) }}
                onClick={() => { setCurrentFocus( `Oval_${index}`) }}
                autoFocus={hasFocus}
                ref={inputRef}

            />
        </>
    );


}

export default Oval