import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container'
import React, { } from "react";
import SSIM from "./ssim/SSIM";
import { faPlaneDeparture, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";


function Header(props) {

  let api_url = 'https://api.aviprice.com'

  return (
    <Router>
      {/* <div className='container-fluid clearfix px-2' >
      <Navbar bg="light" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/ssim">SSMI!</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
      </div> */}
      <Switch>
        <Route path="/">
          <SSIM api_url={api_url} ></SSIM>
        </Route>
      </Switch>
    </Router>
  )
}

export default Header