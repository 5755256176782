import React, {
    useEffect,
    useState,
} from "react";
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
const messageTypeDict = {
    'SHL': 'Slot Historical and Non-Historical Allocation List',
    'SCR': 'Slot Clearance Request/Reply',
    'SMA': 'Schedule Movement Advice',
    'SAL': 'Slot Allocation/Schedule Advice List',
    'SIR': 'Slot/Schedule Information Request/Reply',
    'SAQ': 'Slot/Schedule Availability Query',
    'WCR': 'Waitlist Change Request/Reply',
    'WIR': 'Waitlist Information Request/Reply',
}

var statusDict = {}

function TypeOfRequest(props) {

    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const [selected, setSelected] = useState('')
    const [isValid, setValid] = useState(false)
    const [validComp, setValidComp] = useState(null)
    const statusUpdate = props.statusUpdate
    const updateParent = props.updateParent
    const safeState = props.safeState

    // console.log('Header safeState',safeState)

    function CaptureChange(e) {
        messageValues.header.typeOfRequest = e
        setMessageValues(messageValues)
        setSelected(e)
        updateParent('from header.TypeOfRequest')
    }

    useEffect(() => {
        let code = messageValues.header.typeOfRequest
        console.log('selection changed', code)
        setValid(code in messageTypeDict)
        if (code in messageTypeDict) {
            console.log('valid', messageValues.typeOfRequest)
            statusUpdate('typeOfRequest', 'ok')
            statusDict.typeOfRequest = 'ok'
            let validResponse = <>
                <Badge bg="success mt-3 mb-0">Valid</Badge>
                <p className="text-success mt-0 mb-0">{messageTypeDict[messageValues.header.typeOfRequest]}</p>
            </>
            setValidComp(validResponse)
        } else {
            statusUpdate('typeOfRequest', 'nok')
            console.log('not valid', messageValues.typeOfRequest)
            statusDict.typeOfRequest = 'nok'
            let validResponse = <>
                <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                <p className="text-danger mt-0 mb-0">Type of request not valid</p>
            </>
            setValidComp(validResponse)
        }
    }, [selected])

    function RequestType() {
        if (safeState) {
            return <>
                <Form.Select aria-label="Type of Request" className="w-25" value={messageValues.header.typeOfRequest} onChange={e => { CaptureChange(e.target.value) }}>
                    <option value=""></option>
                    <option value="SCR">SCR</option>
                    <option value="SMA">SMA</option>
                    <option value="SIR">SIR</option>
                </Form.Select>

            </>
        } else {
            return <>
                <Form.Select aria-label="Type of Request" className="w-25" value={messageValues.header.typeOfRequest} onChange={e => { CaptureChange(e.target.value) }}>
                    <option value=""></option>
                    <option value="SHL">SHL</option>
                    <option value="SCR">SCR</option>
                    <option value="SMA">SMA</option>
                    <option value="SAL">SAL</option>
                    <option value="SIR">SIR</option>
                    <option value="SAQ">SAQ</option>
                    <option value="WCR">WCR</option>
                    <option value="WIR">WIR</option>
                </Form.Select>
            </>
        }
    }

    return (
        <>
            <div className="col-12 clearfix border-bottom">
                <div className="col-6 float-start mb-1">
                    <Form.Text className="text-muted">
                        Type of request
                    </Form.Text>
                    {/* <Form.Select aria-label="Type of Request" className="w-25" value={messageValues.header.typeOfRequest} onChange={e => { CaptureChange(e.target.value) }}>
                        <option value=""></option>
                        <option value="SCR">SCR</option>
                        <option value="SMA">SMA</option>
                        <option value="SIR">SIR</option>
                        <option value="SAQ">SAQ</option>
                        <option value="WCR">WCR</option>
                        <option value="WIR">WIR</option>
                    </Form.Select> */}

                    <RequestType></RequestType>
                </div>
                <div className="col-6 float-end mb-1">
                    {validComp}
                </div>
            </div>
        </>
    );
}

function Season(props) {
    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const [validComp, setValidComp] = useState('')
    const [seasonValue, setSeasonValue] = useState('')
    const statusUpdate = props.statusUpdate
    const seasons = props.seasons

    function checkInputValid(inputVal) {
        messageValues.header.season = null
        let currentYear = new Date().getFullYear()
        if (inputVal.length !== 3) {
            statusUpdate('season', 'nok')
            setValidComp(<>
                <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                <p className="text-danger mt-0 mb-0">Set season and year (e.g. Winter 2022 = W22)</p>
            </>)
            return false
        }
        let seasonCode = inputVal.substring(0, 1);
        let seasonYear = inputVal.substring(1);

        if (!['W', 'S'].includes(seasonCode)) {
            statusUpdate('season', 'nok')
            setValidComp(<>
                <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                <p className="text-danger mt-0 mb-0">Set Winter (W) or Summer (S)</p>
            </>)
            return false
        }
        if (isNaN(seasonYear * 1)) {
            statusUpdate('season', 'nok')
            setValidComp(<>
                <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                <p className="text-danger mt-0 mb-0">2nd and 3rd characters should be year</p>
            </>)
            return false

        }
        if ('20' + seasonYear * 1 < currentYear && seasonCode == 'S') {
            statusUpdate('season', 'nok')
            setValidComp(<>
                <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                <p className="text-danger mt-0 mb-0">Year is in the past</p>
            </>)
            return false
        }
        if ('20' + seasonYear * 1 < currentYear - 1 && seasonCode == 'W') {
            statusUpdate('season', 'nok')
            setValidComp(<>
                <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                <p className="text-danger mt-0 mb-0">Winter season can only start last year</p>
            </>)
            return false
        }
        statusUpdate('season', 'ok')
        messageValues.header.season = inputVal
        setValidComp(<>
            <Badge bg="success mt-3 mb-0">Valid</Badge>
            <p className="text-success mt-0 mb-0">Season OK</p>
        </>)
        return false
    }

    function seasonChange(value) {
        setSeasonValue(value)
        messageValues.season = value
        setMessageValues(messageValues)
        checkInputValid(value)
    }

    useEffect(() => {
        checkInputValid('')
    }, [])

    function SeasonSelect() {
        let options = [<option key={0} value={''}></option>]
        function addOption(value, index) {
            options.push(
                <option key={value} value={value}>
                    {value}
                </option>
            )
        }
        seasons.options.forEach(addOption)
        let displayValue = ''
        if (props.messageValues.header != undefined) {
            displayValue = props.messageValues.header.season
        } else {
            console.log('undefined', props)
        }
        let picker = <Form.Select aria-label="Season" className="w-25" value={displayValue} onChange={e => { seasonChange(e.target.value) }}>{options}</Form.Select>
        return picker
    }

    SeasonSelect()

    return <>
        <div className="col-12 clearfix border-bottom">
            <div className="col-6 float-start mb-1">
                <Form.Text className="text-muted">
                    Season
                </Form.Text>
                <SeasonSelect></SeasonSelect>
            </div>
            <div className="col-6 float-start mb-1">
                {validComp}
            </div>
        </div>
    </>
}

function TodaysDate(props) {
    let todaysDateValue = new Date().toLocaleDateString("en-GB", {
        month: "short",
        day: "2-digit",
    }).replace(' ', '').toUpperCase();

    console.log('TodaysDate props', props)

    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const [todaysDate, setTodaysDate] = useState(todaysDateValue)
    const [validComp, setValidComp] = useState('')
    const updateParent = props.updateParent
    const seasonSelected = messageValues.header.season
    const seasons = props.seasons
    const activePeriod = seasons['periods'][seasonSelected]
    // console.log('seasonSelected:', seasonSelected)
    // console.log('seasons:', seasons)
    // console.log('activePeriod:', activePeriod)

    function checkInputDateValid() {
        if (!messageValues.header.dateObj){
            return false
        }
        let inputVal = messageValues.header.dateObj.setHours(0, 0, 0, 0)
        console.log('checkInputDateValid',inputVal, activePeriod)
        if (!activePeriod){
                setValidComp(
                <>
                    <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                    <p className="text-danger mt-0 mb-0">No season set</p>
                </>)
                return false

        }

        if (inputVal < activePeriod['start'] || inputVal > activePeriod['end']){
            setValidComp(
                <>
                    <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                    <p className="text-danger mt-0 mb-0">Date outside of season</p>
                </>)
                return false

        }

        setValidComp(
                <>
                    <Badge bg="success mt-3 mb-0">Valid</Badge>
                    <p className="text-success mt-0 mb-0">Date inside season</p>
                </>
            )
            return false

        // messageValues.header.date = null
        // if (activePeriod) {
        //     console.log('activePeriod is set', activePeriod, inputVal < activePeriod['start'], inputVal > activePeriod['end'])
        //     if (inputVal < activePeriod['start'] || inputVal > activePeriod['end']) {
        //         statusUpdate('todaysDate', 'nok')
        //         setValidComp(
        //             <>
        //                 <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
        //                 <p className="text-danger mt-0 mb-0">Date outside of selected season</p>
        //             </>
        //         )
        //         return false

        //     }

        // }
        // if (inputVal == todaysDateValue) {
        //     statusUpdate('todaysDate', 'ok')
        //     messageValues.header.date = inputVal
        //     setValidComp(
        //         <>
        //             <Badge bg="success mt-3 mb-0">Valid</Badge>
        //             <p className="text-success mt-0 mb-0">Todays date - format DDMMM</p>
        //         </>
        //     )
        //     return false
        // } else {
        //     statusUpdate('todaysDate', 'nok')
        //     setValidComp(
        //         <>
        //             <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
        //             <p className="text-danger mt-0 mb-0">Should be todays date - format DDMMM</p>
        //         </>
        //     )
        // }
    }

    useEffect(() => {
        // dateChange(new Date())
        checkInputDateValid()
    }, [activePeriod])

    function dateChange(value) {
        console.log('dateChange value', value)
        console.log(messageValues)

        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let monthString = months[value.getMonth()]
        let day = value.getDate()
        if (day < 10) {
            day = '0' + day
        } else {
            day = day + ''
        }
        console.log('day+monthString', day + monthString)
        setTodaysDate(day + monthString)
        messageValues.header.date = day + monthString
        messageValues.header.dateObj = value
        setMessageValues(messageValues)
        checkInputDateValid()
        updateParent()
    }

    useEffect((
    ) => { dateChange(new Date()) }, [])

    function TodaysDatePicker() {
        const [startDate, setStartDate] = useState(new Date());
        let minDate = new Date();
        minDate.setDate(minDate.getDate() - 0);

        return (
            <DatePicker className="form-control w-25" minDate={minDate} selected={startDate} value={todaysDate} onChange={(date: Date) => dateChange(date)} dateFormat="ddMMM" />
        );
    }


    return <>
        <div className="col-12 clearfix border-bottom mb-1">
            <div className="col-6 float-start mb-1">
                <Form.Text className="text-muted">
                    Todays Date
                </Form.Text>
                <TodaysDatePicker></TodaysDatePicker>
            </div>
            <div className="col-6 float-start">
                {validComp}
            </div>
        </div>
    </>

}

function AirportInput(props) {
    const [airport, setAirport] = useState('')
    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const [validComp, setValidComp] = useState('')
    const statusUpdate = props.statusUpdate

    function checkAirportValid(inputVal) {
        messageValues.header.airport = null
        if (inputVal.length == 3) {
            statusUpdate('airport', 'ok')
            messageValues.header.airport = inputVal
            setValidComp(
                <>
                    <Badge bg="success mt-3 mb-0">Valid</Badge>
                    <p className="text-success mt-0 mb-0">OK</p>
                </>
            )
            return false
        } else {
            statusUpdate('airport', 'nok')
            setValidComp(
                <>
                    <Badge bg="danger mt-3 mb-0">Not Valid</Badge>
                    <p className="text-danger mt-0 mb-0">Should be 3 chars - eg AMS</p>
                </>
            )
        }
    }

    function airportChange(value) {
        setAirport(value)
        messageValues.airport = value
        checkAirportValid(value)
        console.log('messageValues', messageValues)
        setMessageValues(messageValues)
    }

    return (
        <>

            <div className="col-12 clearfix border-bottom mb-1">
                <div className="col-6 float-start mb-1">
                    <Form.Text className="text-muted">
                        Airport
                    </Form.Text>
                    <Form.Control type="text" className="w-25" maxLength={3} value={airport} onChange={e => { airportChange(e.target.value) }} />
                </div>
                <div className="col-6 float-start">
                    {validComp}
                </div>
            </div>
        </>

    );
}

function UserInitialsInput(props) {
    const [userInitials, setUserInitials] = useState('/')
    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const updateParent = props.updateParent

    function initialsChange(value) {
        setUserInitials(value)
        messageValues.header.userInitials = value
        setMessageValues(messageValues)
        updateParent()
    }

    return (
        <>

            <div className="col-12 clearfix border-bottom mb-1">
                <div className="col-6 float-start mb-1">
                    <Form.Text className="text-muted">
                        User Initials
                    </Form.Text>
                    <Form.Control type="text" className="w-25" maxLength={15} value={userInitials} onChange={e => { initialsChange(e.target.value) }} />
                </div>
                <div className="col-6 float-start">
                </div>
            </div>
        </>

    );
}

function Header(props) {
    console.log('Header Props', props)
    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const setHeaderStatus = props.setHeaderStatus
    const setHeaderOK = props.setHeaderOK
    const updateParent = props.updateParent
    const seasons = props.seasons
    const safeState = props.safeState
    function statusUpdate(key, result) {
        statusDict[key] = result
        if ((Object.values(statusDict).includes('nok'))) {
            setHeaderStatus(<h4 className="me-2 mt-2 text-danger"><FontAwesomeIcon icon={faCircleExclamation} /></h4>)
            setHeaderOK(false)
        } else {
            setHeaderStatus(<h4 className="me-2 mt-2 text-success"><FontAwesomeIcon icon={faCircleCheck} /></h4>)
            setHeaderOK(true)
        }
    }
    return <>
        <div className="col-lg-12 my-auto">
            <TypeOfRequest setMessageValues={setMessageValues} messageValues={messageValues} statusDict={statusDict} statusUpdate={statusUpdate} updateParent={updateParent} safeState={safeState}></TypeOfRequest>
            <UserInitialsInput setMessageValues={setMessageValues} messageValues={messageValues} statusDict={statusDict} statusUpdate={statusUpdate} updateParent={updateParent}></UserInitialsInput>
            <Season setMessageValues={setMessageValues} messageValues={messageValues} statusDict={statusDict} statusUpdate={statusUpdate} seasons={seasons}></Season>
            <TodaysDate setMessageValues={setMessageValues} messageValues={messageValues} statusDict={statusDict} statusUpdate={statusUpdate} updateParent={updateParent} seasons={seasons}></TodaysDate>
            <AirportInput setMessageValues={setMessageValues} messageValues={messageValues} statusDict={statusDict} statusUpdate={statusUpdate}></AirportInput>
        </div>
    </>
}

export default Header