import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function STD(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus == 'flightNo') {
        inputRef.current.focus();
    }

    function stdEntered(value) {
        if (value.length <= 3){
            return
        }
        setCurrentFocus('departureTimeUTC_'+index)
        if (value == '') {
            messageValues['flightDetailLines'][index]['departureTimeUTC'] = ''
            setMessageValues(messageValues)
            updateParent()
        } else {
            let validatedValue = '0000'
            if ((value + '').length == 4) {
                let hours = value.slice(0, 2) * 1
                let minutes = value.slice(2, 4) * 1
                let timeOk = true
                if (hours > 23) {
                    timeOk = false
                }
                if (minutes > 59) {
                    timeOk = false
                }
                if (timeOk) {
                    validatedValue = value
                } else {
                    validatedValue = '0000'
                }
            } else {

            }
            messageValues['flightDetailLines'][index]['departureTimeUTC'] = validatedValue
            setMessageValues(messageValues)
            updateParent()
        }
    }

    let hasFocus = currentFocus === 'departureTimeUTC_'+index ? true : false

    return (
        <>
            <p className="text-muted mb-0 small">STD</p>
            <Form.Control
                key={Math.random()}
                aria-label="Flight number"
                type="text"
                defaultValue={messageValues['flightDetailLines'][index]['departureTimeUTC']}
                onChange={e => { stdEntered(e.target.value) }}
                onClick={() => { setCurrentFocus('departureTimeUTC_'+index) }}
                autoFocus={hasFocus}
                ref={inputRef}

            />
        </>
    );
}

export default STD