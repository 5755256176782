import React, { useRef, useEffect } from "react";
import Form from 'react-bootstrap/Form';

function SeatNumber(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus == 'seats_'+index) {
        inputRef.current.focus();
    }

    function seatNumberEnetered(value) {
        if (value==''){return false}
        if (value.length <=2){return false}
        setCurrentFocus('seats_'+index)
        console.log(value)
        if ((value + '') == '000') {
            messageValues['flightDetailLines'][index]['aircraftSeats'] = '000'
            setMessageValues(messageValues)
            updateParent()

            return false
        }

        let validDatedValue = null
        if (value * 1 < 100) {
            console.log('less than 100', value)
            validDatedValue = '0' + value
        }
        if (value * 1 < 10) {
            console.log('less than 10', value)
            validDatedValue = '00' + value
        }
        if ((value + '').length > 3) {
            console.log('too big', value)
            validDatedValue = null
        }
        if (value * 1 > 99 & value * 1 < 999) {
            validDatedValue = value + ''
        }
        console.log('validDatedValue', validDatedValue)

        if (validDatedValue == '00') {
            validDatedValue = ''
        }

        messageValues['flightDetailLines'][index]['aircraftSeats'] = validDatedValue
        setMessageValues(messageValues)
        updateParent()
    }

    function getDefaultValue() {
        if ((messageValues['flightDetailLines'][index]['aircraftSeats'] + '') == '000') {
            return messageValues['flightDetailLines'][index]['aircraftSeats'] + ''
        }
        let storedValue = messageValues['flightDetailLines'][index]['aircraftSeats'] * 1
        if (storedValue == 0) {
            return null
        }
        if (storedValue < 10) {
            return '00' + storedValue
        }
        if (storedValue < 100) {
            return '0' + storedValue
        }
        if ((storedValue + '').length > 3) {
            return null
        }
        console.log('storedValue returned', storedValue)
        return storedValue
    }

    let hasFocus = currentFocus === 'seats_'+index ? true : false


    return (
        <>
            <p className="text-muted mb-0 small">Seats</p>
            <Form.Control
                key={Math.random()}
                aria-label="N Seats"
                type="text"
                defaultValue={getDefaultValue()}
                onChange={e => { seatNumberEnetered(e.target.value) }}
                autoFocus={hasFocus}
                onInputClick={() => { setCurrentFocus('seats_'+index) }}
                ref={inputRef}

            />
        </>
    );
}

export default SeatNumber