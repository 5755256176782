import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';


function STDepArr(props) {
    console.log(props)
    let index = props.index
    let dep_arr = props.dep_arr
    let messageValues = props.messageValues
    let setMessageValues = props.setMessageValues
    let updateParent = props.updateParent
    let currentFocus = props.currentFocus
    const setCurrentFocus = props.setCurrentFocus
    const inputRef = useRef(null);

    let label = 'STD'
    if (dep_arr == 'arr') {
        label = 'STA'
    }

    if (dep_arr == 'dep') {
        if (inputRef.current && currentFocus == `departureTimeUTC_${index}`) {
            inputRef.current.focus();
        }
    } else {
        if (inputRef.current && currentFocus == `arrivalTimeUTC_${index}`) {
            inputRef.current.focus();
        }

    }

    function stdEntered(value) {
        console.log('value',value)
        let validatedValue = '0000'
        if ((value + '').length == 4) {
            let hours = value.slice(0, 2) * 1
            let minutes = value.slice(2, 4) * 1
            let timeOk = true
            if (hours > 23) {
                timeOk = false
            }
            if (minutes > 59) {
                timeOk = false
            }
            if (timeOk) {
                validatedValue = value
            } else {
                validatedValue = '0000'
            }
        } else {

        }

        if (value.length > 4){
            value = ''
        }

        if (dep_arr == 'dep') {
            messageValues['flightDetailLines'][index]['departureTimeUTC'] = value
        } else {
            messageValues['flightDetailLines'][index]['arrivalTimeUTC'] = value
        }

        setMessageValues(messageValues)
        updateParent()
        if (dep_arr == 'dep') {
            hasFocus = currentFocus === `departureTimeUTC_${index}` ? true : false
        } else {
            hasFocus = currentFocus === `arrivalTimeUTC_${index}` ? true : false
        }
        setFocus()
    
    }

    function getDefaultValue() {
        let storedValue = messageValues['flightDetailLines'][index]['departureTimeUTC']
        if (dep_arr == 'arr') {
            storedValue = messageValues['flightDetailLines'][index]['arrivalTimeUTC']
        }
        return storedValue
    }

    let hasFocus

    if (dep_arr == 'dep') {
        hasFocus = currentFocus === `departureTimeUTC_${index}` ? true : false

    } else {
        hasFocus = currentFocus === `arrivalTimeUTC_${index}` ? true : false
    }

    function setFocus(){
        if (dep_arr == 'dep') {
            setCurrentFocus(`departureTimeUTC_${index}`)
    
        } else {
            setCurrentFocus(`arrivalTimeUTC_${index}`)
        }
    }

    return (
        <>
            <p className="text-muted mb-0 small">{label}</p>
            <Form.Control
                key={Math.random}
                aria-label=""
                type="text"
                defaultValue={getDefaultValue()}
                onChange={e => { stdEntered(e.target.value) }}
                onClick={()=>{setFocus()}}
                autoFocus={hasFocus}
                ref={inputRef}
            />
        </>
    );
}

export default STDepArr