import React from "react";
import Form from 'react-bootstrap/Form';

function Output(props) {

    let safeState = props.safeState
    let setSafeState = props.setSafeState


    function safeStateToggle(){
        setSafeState(!safeState)
    }

    function SafeStateText(){
        if (safeState){
            return <>
                <p className="text-success">Safe State is on. Options are restricted to ususally required parameters</p>
            </>
        }else{
            return <>
            <p className="text-danger">Safe State is off. All options are unrestricted</p>
        </>
        }
    }            

    function SwitchExample() {
        return (
            <>
          <Form>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label="Safe State"
              checked={safeState}
              onChange={safeStateToggle}
            />
          </Form>
          <SafeStateText></SafeStateText>
          <p className="small text-secondary">
          </p>
          </>
        );
      }
      

    return <>
        <SwitchExample></SwitchExample>
    </>

}

export default Output