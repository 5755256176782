import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function TurnFlightNumber(props) {
    let index = props.index
    let dep_arr = props.dep_arr
    let messageValues = props.messageValues
    let setMessageValues = props.setMessageValues
    let updateParent = props.updateParent
    let currentFocus = props.currentFocus
    const setCurrentFocus = props.setCurrentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus == `${dep_arr}FlightNo_${index}`) {
        inputRef.current.focus();
    }

    function isValidString(testString) {
        const regex = /^\d{1,6}[A-Za-z]?$|^$/;
        return regex.test(testString);
      }

    
    function flightNumberEntered(value) {
          
            setCurrentFocus(`${dep_arr}FlightNo_${index}`)
            messageValues['flightDetailLines'][index][`${dep_arr}FlightNo`] = value
            setMessageValues(messageValues)
            updateParent()
    }

    let labelText = 'Arr'
    if (dep_arr == 'dep') {
        labelText = 'Dep'
    }

    let hasFocus = currentFocus === `${dep_arr}FlightNo_${index}` ? true : false
    
    function checkBlur(){
        console.log('check',messageValues['flightDetailLines'][index][`${dep_arr}FlightNo`])
        if (!isValidString(messageValues['flightDetailLines'][index][`${dep_arr}FlightNo`])){
            messageValues['flightDetailLines'][index][`${dep_arr}FlightNo`] = ''
            setMessageValues(messageValues)
            updateParent()
        }
    }

    return (
        <>
            <p className="text-muted mb-0 small">Fl No {labelText}</p>
            <Form.Control
                key={Math.random}
                aria-label="Flight number"
                type="text"
                defaultValue={messageValues['flightDetailLines'][index][`${dep_arr}FlightNo`]}
                onChange={e => { flightNumberEntered(e.target.value) }}
                onBlur={checkBlur}
                onClick={()=>{setCurrentFocus(`${dep_arr}FlightNo_${index}`)}} 
                autoFocus={hasFocus}
                ref={inputRef}
            />
        </>
    );
}

export default TurnFlightNumber