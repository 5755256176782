import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function LastAirport(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus == 'LastAirport_' + index) {
        inputRef.current.focus();
    }

    function airportEntered(value) {
        if (value != '') {
            setCurrentFocus('LastAirport_' + index)
            messageValues['flightDetailLines'][index]['lastAirportIataCode'] = value
            setMessageValues(messageValues)
            updateParent()
        }
    }

    let hasFocus = currentFocus === 'LastAirport_' + index ? true : false

    return (
        <>
            <p className="text-muted mb-0 small">Last</p>
            <Form.Control
                key={Math.random()}
                aria-label="Origin"
                type="text"
                defaultValue={messageValues['flightDetailLines'][index]['lastAirportIataCode']}
                onChange={e => { airportEntered(e.target.value) }}
                onClick={() => { setCurrentFocus('LastAirport_' + index) }}
                autoFocus={hasFocus}
                ref={inputRef}
            />
        </>
    );
}

export default LastAirport