import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";

function isValidUnixTimestamp(value) {
    // Check if it's a number
    if (typeof value !== 'number') {
      return false;
    }
  
    // Check if it's an integer (not a decimal)
    if (!Number.isInteger(value)) {
      return false;
    }
  
    // Optional: Check the range (you might adjust this range according to your needs)
    // Here assuming the timestamp is in milliseconds and within a reasonable range around the current time
    const minDate = new Date('1970-01-01').getTime(); // Earliest reasonable timestamp
    const maxDate = new Date('2100-01-01').getTime(); // Set some maximum reasonable timestamp
    if (value < minDate || value > maxDate) {
      return false;
    }
  
    return true;
  }
  

function getDateInt(input_date) {

    if (isValidUnixTimestamp(input_date)){
        input_date = new Date(input_date)
    }

    let year = (input_date.getFullYear()) + ''
    let monthInt = input_date.getMonth() + 1
    let month = ''
    if (monthInt < 10) {
        month = '0' + (input_date.getMonth() + 1)
    } else {
        month = (monthInt) + ''
    }
    let day = (input_date.getDate()) + ''
    if ((day * 1) < 10) { day = '0' + day }
    let wholeDate = (year + month + day) * 1
    return wholeDate

}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function DateTurn(props) {
    const index = props.index;
    const safeState = props.safeState
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const toggleTurnDateType = props.toggleTurnDateType;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);
    const seasons = props.seasons
    let hasFocus = currentFocus === `turnDate_${index}` ? true : false

    console.log('currentFocus',currentFocus, 'hasFocus',hasFocus)

    function TodaysDatePicker(props) {
   

        function dateChange(value) {
            let dayInt = getDateInt(value)
            const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
            let monthString = months[value.getMonth()]
            let day = value.getDate()
            if (day < 10) {
                day = '0' + day
            } else {
                day = day + ''
            }
            let dateString = day + monthString
            messageValues['flightDetailLines'][index]['turnDate'] = dateString
            messageValues['flightDetailLines'][index]['turnDateInt'] = dayInt
            messageValues['flightDetailLines'][index]['turnDateObj'] = value
            setMessageValues(messageValues)
            updateParent()
            setCurrentFocus('turnDate_'+index)

        }

        let todayDate = new Date()
        let minDate = todayDate
        let maxDate = todayDate
        if (props.messageValues != undefined) {
            let selectedSeason = props.messageValues.header.season
            let seasonStart = seasons['periods'][selectedSeason]['start']
            if (minDate < seasonStart) {
                minDate = seasonStart
            }
            maxDate = seasons['periods'][selectedSeason]['end']
        }
        if (minDate == todayDate){
            minDate.setDate(minDate.getDate() - 4);    
        }

        // check the current date is within the season
        let turnDateInt = messageValues['flightDetailLines'][index]['turnDateInt']
        let seasonSelected = messageValues['header']['season']
        let seasonSelectedDates = props['seasons']['periods'][seasonSelected]
        let seasonStart = seasonSelectedDates['start']
        let seasonStartInt = getDateInt(seasonStart)
        let seasonEnd = seasonSelectedDates['end']
        let seasonEndInt = getDateInt(seasonEnd)

        let dateOk = turnDateInt >= seasonStartInt && turnDateInt <= seasonEndInt
        if (!dateOk) {
            messageValues['flightDetailLines'][index]['turnDate'] = ''
            messageValues['flightDetailLines'][index]['turnDateObj'] = null
        }
        if (safeState == true) {
            maxDate = addDays(new (Date), 365)
        }
        return (
            <DatePicker 
                className="form-control" 
                minDate={minDate} 
                maxDate={maxDate} 
                dateFormat="ddMMM" 
                onClick={()=>{setCurrentFocus(`turnDate_${index}}`)}} 
                onChange={dateChange} 
                value={messageValues['flightDetailLines'][index]['turnDate']} autoFocus={hasFocus}
                preventOpenOnFocus={true}

                />
        );
    }

    return (
        <>
            <p className="text-muted mb-0 small">Date <FontAwesomeIcon onClick={() => { toggleTurnDateType(props) }} icon={faChevronCircleDown} className="float-end me-1 d-none" /></p>
            <TodaysDatePicker messageValues={messageValues} seasons={seasons} index={index} safeState={safeState}></TodaysDatePicker>
        </>
    );
}

export default DateTurn