import Header from './Header'
import FlightDetail from './FlightDetail'
import Footnotes from './Footnotes'
import Accordion from 'react-bootstrap/Accordion';

import React, {
    useEffect,
    useState,
} from "react";
import Output from "./Output";
import Settings from "./Settings";

function build_seasons() {
    let current_year = new Date().getFullYear()  // returns the current year 
    let oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    let return_obj = { 'periods': {}, 'options': [] }
    function lastSunday(year, month) {
        var date = new Date(year, month, 1, 12);
        let weekday = date.getDay();
        let dayDiff = weekday === 0 ? 7 : weekday;
        let lastSunday = date.setDate(date.getDate() - dayDiff);
        return new Date(lastSunday)
    }
    let ticker = -1;
    
    do {
        let ticker_year = current_year + ticker
        let ticker_year_short = ticker_year - 2000
        let winter_start = lastSunday(ticker_year, 10)
        let winter_end = lastSunday(ticker_year +1, 3);
        // winter_end = new Date(winter_end - (24*60*60*1000)); 
        let summer_start = lastSunday(ticker_year, 3)
        let summer_end = lastSunday(ticker_year, 10)
        // summer_end = new Date(summer_end - (24*60*60*1000)); 
        // these lines have been modified to stop seasons in the past being avaliable
        // if (summer_end >= current_year) {
        var today = new Date();
        if (summer_end > today.setDate(today.getDate() - 0) ) {
                return_obj['options'].push('S' + ticker_year_short)
            return_obj['periods']['S' + ticker_year_short] = { 'start': summer_start.setHours(0, 0, 0, 0), 'end': summer_end.setHours(0, 0, 0, 0) }
        }
        // these lines have been modified to stop seasons in the past being avaliable
        // if (winter_end >= current_year) {
        if (winter_end > today.setDate(today.getDate() - 0)) {
                return_obj['options'].push('W' + ticker_year_short)
            return_obj['periods']['W' + ticker_year_short] = { 'start': winter_start.setHours(0, 0, 0, 0), 'end': winter_end.setHours(0, 0, 0, 0) }
        }
        ticker += 1;

    } while (Object.keys(return_obj['periods']).length < 5);

    console.log('seasons',return_obj)
    return return_obj
}


function SSIM(props) {
    const api_url = props.api_url
    const [messageValues, setMessageValues] = useState({ 'header': { 'userInitials': '/' }, 'flightDetailLines': [], 'footer': {}, 'ticker': 0 })
    const [flightDetailLines, setFlightDetailLines] = useState([])
    const [headerStatus, setHeaderStatus] = useState('bg-danger')
    const [headerOK, setHeaderOK] = useState(false)
    const [detailStatus, setDetailStatus] = useState('bg-danger')
    const [safeState, setSafeState] = useState(true)
    const [currentFocus, setCurrentFocus] = useState(null)

    const seasons = build_seasons()
    function logValues() {
        console.log(messageValues)
    }

    async function updateParent(e) {
        console.log('updateParent',e)
        let newValues = messageValues.flightDetailLines
        setFlightDetailLines([...newValues])
    }

    function removeLineSSIM(indexToRemove) {
        console.log('indexToRemove', indexToRemove)
        let newMessageValues = messageValues
        let newFlightDetailLines = newMessageValues['flightDetailLines']
        let filteredFlightDetailLines = newFlightDetailLines.filter(function (value, index) {
            return index !== indexToRemove
        })

        console.log('filteredFlightDetailLines',filteredFlightDetailLines)
        newMessageValues.flightDetailLines = filteredFlightDetailLines
        setMessageValues({...newMessageValues})
    }

    useEffect(() => {
        console.log('safeState Changes')
    }, [
        safeState
    ])

    useEffect(() => {
        console.log('messageValues Changes')
    }, [
        messageValues
    ])

    return (
        <>
            <section className="py-3">
                <div className="col-12 px-2">
                    <div className="col-6 float-start p-2">
                        <h3 onClick={logValues}>SSMI</h3>
                    </div>
                    <div className="col-6 float-end p-2">
                        <Settings safeState={safeState} setSafeState={setSafeState}></Settings>
                    </div>
                    <Accordion defaultActiveKey={['0']} alwaysOpen className="w-100">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{headerStatus} Header</Accordion.Header>
                            <Accordion.Body className='p-1'>
                                <Header messageValues={messageValues} setMessageValues={setMessageValues} setHeaderStatus={setHeaderStatus} setHeaderOK={setHeaderOK} api_url={api_url} updateParent={updateParent} seasons={seasons} safeState={safeState}></Header>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Flight Detail</Accordion.Header>
                            <Accordion.Body className='p-1'>
                                <FlightDetail messageValues={messageValues} setMessageValues={setMessageValues} setDetailStatus={setDetailStatus} updateParent={updateParent} seasons={seasons} headerOK={headerOK} safeState={safeState} currentFocus={currentFocus} setCurrentFocus={setCurrentFocus} removeLineSSIM={removeLineSSIM}></FlightDetail>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" tabIndex={-1}>
                            <Accordion.Header>Footnotes</Accordion.Header>
                            <Accordion.Body className='p-1'>
                                <Footnotes messageValues={messageValues} setMessageValues={setMessageValues} updateParent={updateParent} currentFocus={currentFocus} setCurrentFocus={setCurrentFocus}></Footnotes>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Output</Accordion.Header>
                            <Accordion.Body className='p-1'>
                                <Output key={messageValues} messageValues={messageValues}></Output>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Settings</Accordion.Header>
                            <Accordion.Body className='p-1'>
                                <Settings safeState={safeState} setSafeState={setSafeState}></Settings>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>

        </>
    )

}

export default SSIM;
