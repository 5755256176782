import React, { useRef, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointer, faPen } from "@fortawesome/free-solid-svg-icons";


function AircraftType(props) {
    const index = props.index;

    const messageValues = props.messageValues;
    const flightDetailLines = props.flightDetailLines
    const setFlightDatailLines = props.setFlightDatailLines
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    // console.log('find inputRef', inputRef)
    if (inputRef.current && currentFocus == 'acType_'+index) {
        inputRef.current.focus();
    }

    function toggleManualAc(index) {
        if (flightDetailLines[index]['manualAc']) {
            flightDetailLines[index]['manualAc'] = false
        } else {
            flightDetailLines[index]['manualAc'] = true
        }
        setFlightDatailLines([...flightDetailLines])
        updateParent()
    }

    function acSelected(value) {
        messageValues['flightDetailLines'][index]['iataAircraftCode'] = value
        setMessageValues(messageValues)
        updateParent()
        setCurrentFocus('acType_'+index)
    }

    let hasFocus = currentFocus === 'acType_'+index ? true : false


    if (!messageValues['flightDetailLines'][index]['manualAc']) {
        return (
            <>
                <p className="text-muted mb-0 small">AC Type <FontAwesomeIcon icon={faHandPointer} className="me-1 float-end" onClick={() => toggleManualAc(index * 1)} /></p>
                <Form.Select
                    aria-label="AC Type"
                    onChange={e => { acSelected(e.target.value) }}
                    value={messageValues['flightDetailLines'][index]['iataAircraftCode']}
                    className="float-start"
                    onClick={()=>{setCurrentFocus('acType_'+index)}}
                    key={Math.random}
                    autoFocus={hasFocus}

                >
                    <option></option>
                    <option value="E90">E90</option>
                    <option value="295">295</option>
                    <option value="7M8">7M8</option>
                    <option value="73H">73H</option>
                    <option value="73W">73W</option>
                    <option value="75W">75W</option>
                    <option value="76W">76W</option>
                    <option value="788">788</option>
                    <option value="789">789</option>
                </Form.Select>
            </>
        );
    } else {
        return (
            <>

                <p className="text-muted mb-0 small">AC Type <FontAwesomeIcon icon={faPen} className="me-1 float-end" onClick={() => toggleManualAc(index * 1)} /></p>
                <Form.Control 
                    key={Math.random} 
                    aria-label="AC Type" 
                    type="text" 
                    maxLength={5}
                    defaultValue={messageValues['flightDetailLines'][index]['iataAircraftCode']}
                    onInputClick={()=>{setCurrentFocus('acType_'+index)}}
                    onChange={e => { acSelected(e.target.value) }} 
                    autoFocus={hasFocus}
                    ref={inputRef}
                    />
            </>
        );

    }
}

export default AircraftType