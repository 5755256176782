import React, { useRef, useEffect } from "react";
import Form from 'react-bootstrap/Form';

function ActionCode(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);
    // console.log('find inputRef', inputRef)
    if (inputRef.current && currentFocus == 'airline_' + index) {
        inputRef.current.focus();
    }

    function airlineSelected(e) {
        const newMessageValues = { ...messageValues };
        newMessageValues['flightDetailLines'][index]['airline'] = e.target.value;
        setMessageValues(newMessageValues);
        updateParent();
        setCurrentFocus('airline_' + index)
    }

    let hasFocus = currentFocus === 'airline_' + index ? true : false

    return (
        <div>
            <p className="text-muted mb-0 small">Airline</p>
            <Form.Select
                aria-label="Action Codes"
                value={messageValues['flightDetailLines'][index]['airline']}
                onChange={e => airlineSelected(e)}
                onClick={() => { setCurrentFocus('airline_' + index) }}
                className="float-start"
                key={Math.random}
                ref={inputRef}
                autoFocus={hasFocus}
            >
                <option></option>
                <option value="TOM">TOM</option>
                <option value="BLX">BLX</option>
                <option value="X3">X3</option>
                <option value="OR">OR</option>
                <option value="TB">TB</option>
                <option value="BY">BY</option>
            </Form.Select>
        </div>
    );
}

export default ActionCode;
