import React, {
    useEffect,
    useState,
    useCallback
} from "react";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneArrival, faPlaneDeparture, faCircleXmark, faFileImport, faExclamationTriangle, faEyeSlash, faEye, faPen, faHandPointer, faChevronCircleUp, faCircleExclamation, faCircleCheck, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import Badge from 'react-bootstrap/Badge';
import Validate from "./Validate";
import DatePicker from "react-datepicker";
import ActionCode_EXT from "./inputs/ActionCode";
import Airline_EXT from "./inputs/Airline";
import FlightNumber_EXT from "./inputs/FlightNo";
import DateStart_EXT from "./inputs/DateStart";
import DateTurn_EXT from "./inputs/DateTurn";
import Seats_EXT from "./inputs/Seats";
import AircraftType_EXT from "./inputs/AircraftType";
import OriginAirport_EXT from "./inputs/Origin";
import DestinationAirport_EXT from "./inputs/Destination";
import NextAirport_EXT from "./inputs/Next";
import LastAirport_EXT from "./inputs/Last";

import STA_EXT from "./inputs/STA";
import STD_EXT from "./inputs/STD";
import FlightCode_EXT from "./inputs/FlightCode";
import TurnAirlineCode_EXT from "./inputs/TurnAirlineCode"
import TurnFlightNumber_EXT from "./inputs/TurnFlightNumber"
import STDepArr_EXT from './inputs/STDepArr'
import Oval_EXT from './inputs/Oval'
import "react-datepicker/dist/react-datepicker.css";

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

var statusDict = {}

let arrayTranscribe = {
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    7: 6
}

function getDateInt(input_date) {
    let year = (input_date.getFullYear()) + ''
    let monthInt = input_date.getMonth() + 1
    let month = ''
    if (monthInt < 10) {
        month = '0' + (input_date.getMonth() + 1)
    } else {
        month = (monthInt) + ''
    }
    let day = (input_date.getDate()) + ''
    if ((day * 1) < 10) { day = '0' + day }
    let wholeDate = (year + month + day) * 1
    return wholeDate

}

function FlightDetail(props) {

    // flightDetailLines is a list of all the detail lines in the message
    const [flightDetailLines, setFlightDatailLines] = useState([])
    const [daysOfWeek, setDaysOfWeek] = useState({ 0: [0, 0, 0, 0, 0, 0, 0] })
    const [originAiroort, setOriginAirport] = useState({})
    const [flightCodes, setFlightCodes] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0)
    const headerOK = props.headerOK
    const setMessageValues = props.setMessageValues
    const messageValues = props.messageValues
    const updateParent = props.updateParent
    const seasons = props.seasons
    const setDetailStatus = props.setDetailStatus
    const safeState = props.safeState
    const currentFocus = props.currentFocus
    const setCurrentFocus = props.setCurrentFocus
    const removeLineSSIM = props.removeLineSSIM
    const header = props.messageValues.header
    function statusUpdate(key, result) {
        statusDict[key] = result
        console.log(statusDict, Object.values(statusDict).includes('nok'))
        if ((Object.values(statusDict).includes('nok'))) {
            setDetailStatus(<h4 className="me-2 mt-2 text-danger"><FontAwesomeIcon icon={faCircleExclamation} /></h4>)
        } else {
            setDetailStatus(<h4 className="me-2 mt-2 text-success"><FontAwesomeIcon icon={faCircleCheck} /></h4>)
        }
    }

    useEffect(() => {
        statusUpdate('detail', 'nok')
    }, [])

    messageValues.flightDetailLines = flightDetailLines
    setMessageValues(messageValues)

    function removeLine(indexToRemove) {
        let filteredFlightDetailLines = flightDetailLines.filter(function (value, index) {
            return index !== indexToRemove
        })
        setFlightDatailLines([...filteredFlightDetailLines])
        removeLineSSIM(indexToRemove)
    }

    function importText(indexToImport) {
        if (flightDetailLines[indexToImport]['showRawInput']) {
            flightDetailLines[indexToImport]['showRawInput'] = false
        } else {
            flightDetailLines[indexToImport]['showRawInput'] = true
        }
        setFlightDatailLines([...flightDetailLines])
        updateParent()
    }

    function toggleDateSpan(indexToImport) {
        if (flightDetailLines[indexToImport]['showDateSpan']) {
            console.log('set false', indexToImport)
            flightDetailLines[indexToImport]['showDateSpan'] = false
        } else {
            console.log('set true', indexToImport)

            flightDetailLines[indexToImport]['showDateSpan'] = true
        }
        setFlightDatailLines([...flightDetailLines])
        updateParent()

    }

    function newArrival() {
        let newIndex = currentIndex + 1
        setCurrentIndex(newIndex)
        let existingDaysOfWeek = daysOfWeek
        existingDaysOfWeek[newIndex] = [0, 0, 0, 0, 0, 0, 0]
        setDaysOfWeek(existingDaysOfWeek)
        flightDetailLines.push(
            { 'type': 'arrival', 'showRawInput': false, 'showDateSpan': false, 'rawInputValue': null, 'manualAc': false, 'actionCode': null, 'airline': null, 'flightNo': null, 'periodStart': null, 'periodEnd': null, 'weekdays': [0, 0, 0, 0, 0, 0, 0], 'aircraftSeats': null, 'iataAircraftCode': null, 'originAirportIataCode': null, 'arrivalTimeUTC': null, 'typeOfFlight': null }
        )
        setFlightDatailLines([...flightDetailLines])
    }

    function newDeparture() {
        let newIndex = currentIndex + 1
        setCurrentIndex(newIndex)
        let existingDaysOfWeek = daysOfWeek
        existingDaysOfWeek[newIndex] = [0, 0, 0, 0, 0, 0, 0]
        setDaysOfWeek(existingDaysOfWeek)
        flightDetailLines.push(
            { 'type': 'departure', 'showRawInput': false, 'showDateSpan': false, 'rawInputValue': null, 'manualAc': false, 'actionCode': null, 'airline': null, 'flightNo': null, 'periodStart': null, 'periodEnd': null, 'weekdays': [0, 0, 0, 0, 0, 0, 0], 'aircraftSeats': null, 'iataAircraftCode': null, 'originAirportIataCode': null, 'arrivalTimeUTC': null, 'typeOfFlight': null }
        )
        setFlightDatailLines([...flightDetailLines])
    }

    function newTurn() {
        let newIndex = currentIndex + 1
        setCurrentIndex(newIndex)
        let existingDaysOfWeek = daysOfWeek
        existingDaysOfWeek[newIndex] = [0, 0, 0, 0, 0, 0, 0]
        setDaysOfWeek(existingDaysOfWeek)
        flightDetailLines.push(
            { 'type': 'turn', 'showRawInput': false, 'showDateSpan': false, 'rawInputValue': null, 'manualAc': false, 'turnDateType': 'single', 'actionCode': null, 'airlineArrive': null, 'flightNoArrive': null, 'airlineDepart': null, 'flightNoDepart': null, 'flightDate': null, 'periodEnd': null, 'weekdays': [0, 0, 0, 0, 0, 0, 0], 'aircraftSeats': null, 'iataAircraftCode': null, 'originAirportIataCode': null, 'departureTimeUTC': null, 'destinationAirportIataCode': null, 'arrivalTimeUTC': null, 'typeOfFlightDeparture': null, 'typeOfFlightArrival': null, 'Oval': null }
        )
        setFlightDatailLines([...flightDetailLines])
    }


    //create your forceUpdate hook
    function useForceUpdate() {
        const [value, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update state to force render
    }

    function FlightDays(props) {
        const forceUpdate = useForceUpdate();
        let index = props.index
        let messageValues = props.messageValues
        let weekdays = messageValues['flightDetailLines'][index]['weekdays']

        function dayOfWeekClicked(day, dayNumbersBetween) {

            console.log('clicked:', day, dayNumbersBetween, dayNumbersBetween.includes(day))
            if (!dayNumbersBetween.includes(day)) {
                console.log('not valid today')
                return false
            }
            let newDaysOfWeek = weekdays
            console.log('weekdays', weekdays)
            let arrayVal = arrayTranscribe[day]
            let existing = newDaysOfWeek[arrayVal]
            if (existing == 0) {
                newDaysOfWeek[arrayVal] = day
            } else {
                newDaysOfWeek[arrayVal] = 0
            }
            // setDaysOfWeek(newDaysOfWeek)
            messageValues['flightDetailLines'][index]['weekdays'] = newDaysOfWeek
            setMessageValues(messageValues)
            forceUpdate()
            updateParent()
        }

        let periodStartObj = messageValues['flightDetailLines'][index]['periodStartObj']
        let periodEndObj = messageValues['flightDetailLines'][index]['periodEndObj']

        function getDatesBetweenDates(startDate, endDate) {
            if (startDate == null || endDate == null) {
                return []
            }
            let dates = []
            const theDate = new Date(startDate)
            while (theDate < new Date(endDate)) {
                dates = [...dates, new Date(theDate)]
                theDate.setDate(theDate.getDate() + 1)
            }
            dates = [...dates, new Date(endDate)]
            return dates
        }
        let datesBetween = getDatesBetweenDates(periodStartObj, periodEndObj)
        let dayNumbersBetween = []
        let dayTranscribe = {
            // sunday
            0: 7,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6
        }
        function getWeekeday(value) {
            if (!dayNumbersBetween.includes(value.getDay())) {
                dayNumbersBetween.push(dayTranscribe[value.getDay()])
            }
        }
        datesBetween.forEach(getWeekeday)

        function getTextClass(dayNumber) {
            if (dayNumbersBetween.includes(dayNumber)) {
                return 'mb-0 text-success'
            }
            else {
                return 'mb-0 text-dark'
            }
        }

        if (messageValues['flightDetailLines'][index]['showDateSpan']) {


            return (
                <>
                    <p className="text-muted mb-0 small">Days of week </p>
                    <div className="col-4 float-start">
                        <div className="col-4 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(1, dayNumbersBetween) }}><p className={getTextClass(1)}>{weekdays[0]}</p></Badge></h3>
                        </div>
                        <div className="col-4 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(2, dayNumbersBetween) }}><p className={getTextClass(2)}>{weekdays[1]}</p></Badge></h3>
                        </div>
                        <div className="col-4 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(3, dayNumbersBetween) }}><p className={getTextClass(3)}>{weekdays[2]}</p></Badge></h3>
                        </div>
                    </div>
                    <div className="col-8 float-end">
                        <div className="col-2 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(4, dayNumbersBetween) }}><p className={getTextClass(4)}>{weekdays[3]}</p></Badge></h3>
                        </div>
                        <div className="col-2 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(5, dayNumbersBetween) }}><p className={getTextClass(5)}>{weekdays[4]}</p></Badge></h3>
                        </div>
                        <div className="col-2 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(6, dayNumbersBetween) }}><p className={getTextClass(6)}>{weekdays[5]}</p></Badge></h3>
                        </div>
                        <div className="col-2 float-start">
                            <h3><Badge bg="light" onClick={() => { dayOfWeekClicked(7, dayNumbersBetween) }}><p className={getTextClass(7)}>{weekdays[6]}</p></Badge></h3>
                        </div>
                    </div>
                </>

            );
        }
        return (
            <>
                <p className="text-muted mb-0 small"><s>Days of week</s></p>
                <div className="col-12 float-start">
                </div>
            </>
        )

    }

    useEffect(() => {
        console.log('messageValues chnaged', messageValues);
    }, [messageValues]);


    function toggleTurnDateType(props) {
        let index = props.index * 1
        console.log(index, messageValues['flightDetailLines'][index]['turnDateType'])

        if (messageValues['flightDetailLines'][index]['turnDateType'] == 'range') {
            messageValues['flightDetailLines'][index]['turnDateType'] = 'single'
            updateParent()
            return false
        }
        if (messageValues['flightDetailLines'][index]['turnDateType'] == 'single') {
            messageValues['flightDetailLines'][index]['turnDateType'] = 'range'
            updateParent()
            return false
        }
    }

    function DateStart(props) {
        let index = props.index * 1
        let tabIndex = 0
        if (props.tabIndex) { tabIndex = props.tabIndex }

        function TodaysDatePicker(props) {
            let seasons = props.seasons
            const [startDate, setStartDate] = useState(new Date());

            function dateChange(value) {
                let dayInt = getDateInt(value)
                const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
                let monthString = months[value.getMonth()]
                let day = value.getDate()
                if (day < 10) {
                    day = '0' + day
                } else {
                    day = day + ''
                }
                setStartDate(day + monthString)
                let dateString = day + monthString
                messageValues['flightDetailLines'][index]['periodStart'] = dateString
                messageValues['flightDetailLines'][index]['periodStartInt'] = dayInt
                messageValues['flightDetailLines'][index]['periodStartObj'] = value
                messageValues['flightDetailLines'][index]['weekdays'] = [0, 0, 0, 0, 0, 0, 0]
                setMessageValues(messageValues)
                updateParent()
            }

            let todayDate = new Date()
            let minDate = todayDate
            let maxDate = todayDate
            if (props.messageValues != undefined) {
                let selectedSeason = props.messageValues.header.season
                let seasonStart = seasons['periods'][selectedSeason]['start']
                if (minDate < seasonStart) {
                    minDate = seasonStart
                }
                maxDate = seasons['periods'][selectedSeason]['end']
            }

            // check the current date is within the season
            let periodStartInt = messageValues['flightDetailLines'][index]['periodStartInt']
            let seasonSelected = messageValues['header']['season']
            let seasonSelectedDates = props['seasons']['periods'][seasonSelected]
            let seasonStart = seasonSelectedDates['start']
            let seasonStartInt = getDateInt(seasonStart)
            let seasonEnd = seasonSelectedDates['end']
            let seasonEndInt = getDateInt(seasonEnd)

            let dateOk = periodStartInt >= seasonStartInt && periodStartInt <= seasonEndInt
            if (!dateOk) {
                messageValues['flightDetailLines'][index]['periodStart'] = ''
                messageValues['flightDetailLines'][index]['periodStartObj'] = null
                // setMessageValues(messageValues)
            }
            if (safeState == true) {
                maxDate = addDays(new (Date), 365)
            }
            return (
                <DatePicker className="form-control" minDate={minDate} maxDate={maxDate} dateFormat="ddMMM" onChange={dateChange} value={messageValues['flightDetailLines'][index]['periodStart']} tabIndex={0} />
            );
        }

        return (
            <>
                <p className="text-muted mb-0 small">Date Start</p>
                <TodaysDatePicker messageValues={messageValues} seasons={seasons}></TodaysDatePicker>
            </>
        );
    }

    function DateEnd(props) {
        let index = props.index
        let tabIndex = 0
        if (props.tabIndex) { tabIndex = props.tabIndex }

        function TodaysDatePicker(props) {
            let seasons = props.seasons

            function dateChange(value) {
                let dayInt = getDateInt(value)
                const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
                let monthString = months[value.getMonth()]
                let day = value.getDate()
                if (day < 10) {
                    day = '0' + day
                } else {
                    day = day + ''
                }
                messageValues['flightDetailLines'][index]['periodEnd'] = day + monthString
                messageValues['flightDetailLines'][index]['periodEndInt'] = dayInt
                messageValues['flightDetailLines'][index]['periodEndObj'] = value
                setMessageValues(messageValues)
                updateParent()
            }

            let todayDate = new Date()
            let minDate = todayDate
            let maxDate = todayDate
            if (props.messageValues != undefined) {
                let selectedSeason = props.messageValues.header.season
                let seasonStart = seasons['periods'][selectedSeason]['start']
                if (minDate < seasonStart) {
                    minDate = seasonStart
                }
                maxDate = seasons['periods'][selectedSeason]['end']
            }

            let periodEndInt = messageValues['flightDetailLines'][index]['periodEndInt']
            let seasonSelected = messageValues['header']['season']
            let seasonSelectedDates = props['seasons']['periods'][seasonSelected]
            let seasonStart = seasonSelectedDates['start']
            let seasonStartInt = getDateInt(seasonStart)
            let seasonEnd = seasonSelectedDates['end']
            let seasonEndInt = getDateInt(seasonEnd)

            let dateOk = periodEndInt >= seasonStartInt && periodEndInt <= seasonEndInt
            if (!dateOk) {
                messageValues['flightDetailLines'][index]['periodEnd'] = ''
                messageValues['flightDetailLines'][index]['periodEndObj'] = null
                setMessageValues(messageValues)
            }

            if (safeState == true) {
                maxDate = addDays(new (Date), 365)
            }

            return (
                <DatePicker className="form-control" minDate={minDate} maxDate={maxDate} dateFormat="ddMMM" onChange={dateChange} value={messageValues['flightDetailLines'][index]['periodEnd']} tabIndex={tabIndex} autoFocus={true} />
            );

        }

        if (messageValues['flightDetailLines'][index]['showDateSpan']) {
            return (
                <>
                    <p className="text-muted mb-0 small">Date End</p>
                    <TodaysDatePicker messageValues={messageValues} seasons={seasons}></TodaysDatePicker>
                </>
            );
        } else {
            return (
                <>
                    <p className="text-muted mb-0 small"><s>Date End</s></p>
                    {/* <DatePicker className="form-control" disabled={true} /> */}
                </>)
        }
    }

    function TurnFlightCode(props) {
        let index = props.index
        let dep_arr = props.dep_arr

        let label = 'Srv Dep'
        if (dep_arr == 'arr') {
            label = 'Srv Arr'
        }

        function turnFlightCodeSelected(value) {
            let newFlightCodes = flightCodes
            newFlightCodes[index] = value
            setFlightCodes(newFlightCodes)
            messageValues['flightDetailLines'][index][`${dep_arr}TypeOfFlight`] = value
            setMessageValues(messageValues)
            updateParent()
        }

        if (safeState) {
            return (
                <>
                    <p className="text-muted mb-0 small">{label}</p>
                    <Form.Select aria-label="Service Type" defaultValue={messageValues['flightDetailLines'][index][`${dep_arr}TypeOfFlight`]} onChange={e => { turnFlightCodeSelected(e.target.value) }} className="float-start">
                        <option></option>
                        <option value="C">C - Charter – Passenger only</option>
                        <option value="J">J - Scheduled – Passenger Normal Service</option>
                        <option value="P">P - Positioning Flights – Non Revenue (ferry/delivery/demo)</option>
                        <option value="X">X - Technical Stop</option>
                    </Form.Select>
                </>
            );

        } else {
            return (
                <>
                    <p className="text-muted mb-0 small">{label}</p>
                    <Form.Select aria-label="Service Type" defaultValue={messageValues['flightDetailLines'][index][`${dep_arr}TypeOfFlight`]} onChange={e => { turnFlightCodeSelected(e.target.value) }} className="float-start">
                        <option></option>
                        <option value="A">A - Additional Cargo/Mail</option>
                        <option value="C">C - Charter – Passenger only</option>
                        <option value="E">E - Special VIP Flight (FAA/Government)</option>
                        <option value="F">F - Scheduled – Cargo and/or Mail</option>
                        <option value="G">G - Additional Flights – Passenger Normal Service</option>
                        <option value="H">H - Charter – Cargo and/or Mail</option>
                        <option value="I">I - Ambulance Flight</option>
                        <option value="J">J - Scheduled – Passenger Normal Service</option>
                        <option value="K">K - Training Flights</option>
                        <option value="L">L - Football European Championship 2024</option>
                    {/* <option value="L">L - Passenger and Cargo and/or Mail</option> */}
                        <option value="M">M - Mail Service</option>
                        <option value="O">O - Charter requiring special handling (e.g. migrants, immigrants)</option>
                        <option value="P">P - Positioning Flights – Non Revenue (ferry/delivery/demo)</option>
                        <option value="T">T - Technical Test</option>
                        <option value="W">W - Military</option>
                        <option value="X">X - Technical Stop</option>
                    </Form.Select>
                </>
            );

        }

    }

    function TurnDateRange(props) {
        let index = props.index
        let messageValues = props.messageValues
        console.log('find messageValues', messageValues['flightDetailLines'][index])
        if (messageValues['flightDetailLines'][index]['turnDateType'] == 'range') {
            return <>
                <div className="col-12 d-flex justify-content-around">
                    <div className="col-6 float-start">
                        <div className="col-3 float-start pe-1">
                            <DateStart index={index}></DateStart>
                        </div>
                        <div className="col-3 float-start pe-1">
                            <FontAwesomeIcon className="float-end me-3" onClick={() => toggleDateSpan(index * 1)} />
                            <DateEnd index={index}></DateEnd>
                        </div>
                        <div className="col-6 float-start">
                            <FlightDays index={index} messageValues={messageValues}></FlightDays>
                        </div>
                    </div>
                </div>


            </>
        }
        return <></>
    }

    function RawInput(props) {
        let index = props.index
        function rawInputEntered(value) {
            let temp = flightDetailLines
            temp[index]['rawInputValue'] = value
            setFlightDatailLines([...temp])
            updateParent()

        }
        if (!flightDetailLines[index]['showRawInput']) {
            return <></>
        }
        return <>
            <>
                <div className="col-12 float-start">
                    <p className="text-muted mb-1 small">Raw Import <Badge className="float-end" bg="warning" text="dark">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
                        Input Not Validated
                    </Badge></p>
                    <Form.Control key={'rawInput' + index} aria-label="Raw SSMI" type="text" defaultValue={flightDetailLines[index]['rawInputValue']} onBlur={e => { rawInputEntered(e.target.value) }} />
                </div>
            </>
        </>
    }


    function DetailLines() {
        let lines = []

        function buildLine(value, index) {
            let type = value.type
            let line

            let dInputs = 'col-12'
            if (flightDetailLines[index]['showRawInput']) {
                dInputs = 'col-12 d-none'
            }
            let eyeIcon
            if (!flightDetailLines[index]['showDateSpan']) {
                eyeIcon = faEyeSlash
            } else {
                eyeIcon = faEye
            }

            if (type == 'arrival') {
                line =
                    <div key={index} className="card p-1 mb-1">
                        <div className="col-12">
                            <p className="text-muted mb-0">
                                <Validate line={flightDetailLines[index]} header={header}></Validate>
                                <span className="ms-2">Arrival</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="float-end me-1 text-danger" onClick={() => { removeLine(index) }} />
                                <FontAwesomeIcon icon={faFileImport} className="float-end me-2" onClick={() => { importText(index) }} />
                            </p>
                            <div className={dInputs}>
                                <Form>
                                    <div className="col-4 float-start">
                                        <div className="col-1 float-start text-muted mt-4">
                                            <FontAwesomeIcon icon={faPlaneArrival} className="ms-1" />
                                        </div>
                                        <div className="col-4 float-start pe-1">
                                            <ActionCode_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></ActionCode_EXT>
                                        </div>
                                        <div className="col-3 float-start pe-1">
                                            <Airline_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></Airline_EXT>
                                        </div>
                                        <div className="col-4 float-start pe-1">
                                            <FlightNumber_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></FlightNumber_EXT>
                                        </div>
                                    </div>
                                    <div className="col-1 float-start me-1">
                                            <DateStart_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} seasons={seasons}></DateStart_EXT>
                                    </div>
                                    <div className="col-1 float-start me-1">
                                        <Seats_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState}></Seats_EXT>
                                    </div>
                                    <div className="col-4 float-start">
                                        <div className="col-3 float-start pe-1">
                                            <AircraftType_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines} flightDetailLines={flightDetailLines}></AircraftType_EXT>
                                        </div>
                                        <div className="col-3 float-start pe-1">
                                            <OriginAirport_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines} setOriginAirport={setOriginAirport}></OriginAirport_EXT>
                                        </div>
                                        <div className="col-3 float-start pe-1">
                                            <LastAirport_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines} setOriginAirport={setOriginAirport}></LastAirport_EXT>
                                        </div>
                                        <div className="col-3 float-start pe-1">
                                            <STA_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></STA_EXT>
                                        </div>
                                    </div>
                                    <div className="col-1 float-start">
                                        <FlightCode_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></FlightCode_EXT>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <RawInput index={index}></RawInput>

                    </div>
            }

            if (type == 'departure') {

                line =
                    <div key={index} className="card p-1 mb-1">
                        <p className="text-muted mb-0">
                            <Validate line={flightDetailLines[index]} header={header}></Validate>
                            <span className="ms-2">Departure</span>
                            <FontAwesomeIcon icon={faCircleXmark} className="float-end me-1 text-danger" onClick={() => { removeLine(index) }} />
                            <FontAwesomeIcon icon={faFileImport} className="float-end me-2" onClick={() => { importText(index) }} />
                        </p>
                        <div className={dInputs}>
                            <div className="col-4 float-start">
                                <div className="col-1 float-start">
                                    <FontAwesomeIcon icon={faPlaneDeparture} className="ms-1 text-muted mt-4" />
                                </div>
                                <div className="col-4 float-start pe-1">
                                    <ActionCode_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></ActionCode_EXT>
                                </div>
                                <div className="col-3 float-start pe-1">
                                    <Airline_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></Airline_EXT>
                                </div>
                                <div className="col-4 float-start pe-1">
                                    <FlightNumber_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></FlightNumber_EXT>
                                </div>
                            </div>
                            <div className="col-1 float-start pe-1">
                                    <DateStart_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} seasons={seasons}></DateStart_EXT>
                            </div>
                            <div className="col-1 float-start me-1">
                                <Seats_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState}></Seats_EXT>
                            </div>
                            <div className="col-4 float-start">
                                <div className="col-3 float-start pe-1">
                                    <AircraftType_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}  flightDetailLines={flightDetailLines}></AircraftType_EXT>
                                </div>
                                <div className="col-3 float-start pe-1">
                                    <NextAirport_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></NextAirport_EXT>
                                </div>
                                <div className="col-3 float-start pe-1">
                                    <DestinationAirport_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></DestinationAirport_EXT>
                                </div>
                                <div className="col-3 float-start pe-1">
                                    <STD_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></STD_EXT>
                                </div>
                            </div>
                            <div className="col-1 float-start">
                                <FlightCode_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></FlightCode_EXT>
                            </div>
                        </div>
                        <RawInput index={index}></RawInput>
                    </div>
            }

            if (type == 'turn') {
                line =
                    <div key={index} className="card p-1 mb-1">
                        <p className="text-muted mb-0">
                            <Validate line={flightDetailLines[index]} header={header}></Validate>
                            <span className="ms-2">Turn</span>
                            <FontAwesomeIcon icon={faCircleXmark} className="float-end me-1 text-danger" onClick={() => { removeLine(index) }} />
                            <FontAwesomeIcon icon={faFileImport} className="float-end me-2" onClick={() => { importText(index) }} />
                        </p>
                        <div className={dInputs}>
                            <div className="col-12 clearfix">
                                <div className="col-4 float-start">
                                    <div className="col-1 float-start pe-1">
                                        <FontAwesomeIcon icon={faPlaneArrival} className="ms-1 text-muted mt-4" />
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        <ActionCode_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></ActionCode_EXT>
                                    </div>
                                    <div className="col-3 float-start pe-1">
                                        <TurnAirlineCode_EXT index={index} dep_arr='arr' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnAirlineCode_EXT>
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        <TurnFlightNumber_EXT index={index} dep_arr='arr' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnFlightNumber_EXT>
                                    </div>
                                </div>
                                <div className="col-4 float-start">
                                    <div className="col-4 float-start">
                                        <DateTurn_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} seasons={seasons} toggleTurnDateType={toggleTurnDateType}></DateTurn_EXT>
                                    </div>

                                    <div className="col-3 float-start ps-1">
                                        <Seats_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState}></Seats_EXT>
                                    </div>
                                    <div className="col-5 float-start ps-1">
                                        <AircraftType_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}  flightDetailLines={flightDetailLines}></AircraftType_EXT>
                                    </div>

                                </div>
                                <div className="col-3 float-start ps-1">
                                    <div className="col-5 float-start pe-1">
                                        <OriginAirport_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines} setOriginAirport={setOriginAirport}></OriginAirport_EXT>
                                    </div>
                                    <div className="col-5 float-start pe-1">
                                        <STDepArr_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} dep_arr={'arr'}></STDepArr_EXT>

                                    </div>
                                    <div className="col-2 float-start pe-1">
                                        {'\u00A0'}
                                    </div>
                                </div>
                                <div className="col-1 float-start">
                                    <TurnFlightCode index={index} dep_arr={'arr'}></TurnFlightCode>
                                </div>
                                <TurnDateRange index={index} messageValues={messageValues}></TurnDateRange>
                            </div>
                            <div className="col-12 clearfix border-top mt-1">
                                <div className="col-4 float-start">

                                    <div className="col-1 float-start pe-1">
                                        <FontAwesomeIcon icon={faPlaneDeparture} className="ms-1 text-muted mt-4" />
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        {'\u00A0'}
                                    </div>
                                    <div className="col-3 float-start pe-1">
                                        <TurnAirlineCode_EXT index={index} dep_arr='dep' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnAirlineCode_EXT>
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        <TurnFlightNumber_EXT index={index} dep_arr='dep' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnFlightNumber_EXT>
                                    </div>
                                </div>
                                <div className="col-4 float-start">
                                    <div className="col-4 float-start">
                                        {'\u00A0'}
                                    </div>
                                    <div className="col-3 float-start ps-1">
                                        {'\u00A0'}
                                    </div>
                                    <div className="col-5 float-start ps-1">
                                        {'\u00A0'}
                                    </div>
                                </div>
                                <div className="col-3 float-start ps-1">
                                    <div className="col-5 float-start pe-1">
                                        <DestinationAirport_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} setFlightDatailLines={setFlightDatailLines}></DestinationAirport_EXT>
                                    </div>
                                    <div className="col-5 float-start pe-1">
                                        <STDepArr_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} dep_arr={'dep'}></STDepArr_EXT>
                                    </div>
                                    <div className="col-2 float-start pe-1">
                                        <Oval_EXT index={index} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></Oval_EXT>
                                    </div>
                                </div>
                                <div className="col-1 float-start">
                                    <TurnFlightCode index={index} dep_arr={'dep'}></TurnFlightCode>
                                </div>
                                <TurnDateRange index={index} messageValues={messageValues}></TurnDateRange>
                            </div>
                        </div>
                        <RawInput index={index}></RawInput>
                    </div>
            }

            lines.push(line)
        }

        function buildLineSIR(value, index){
            let type = value.type
            let line

            let dInputs = 'col-12'
            if (flightDetailLines[index]['showRawInput']) {
                dInputs = 'col-12 d-none'
            }
            let eyeIcon
            if (!flightDetailLines[index]['showDateSpan']) {
                eyeIcon = faEyeSlash
            } else {
                eyeIcon = faEye
            }

            if (type == 'arrival' || type == 'departure') {

                let ac_icon = <FontAwesomeIcon icon={faPlaneArrival} className="ms-1" />
                if (type == 'departure'){ac_icon = <FontAwesomeIcon icon={faPlaneDeparture} className="ms-1" />}
                let headerText = 'Arrival'
                if (type == 'departure'){headerText = 'Departure'}

                line =
                    <div key={index} className="card p-1 mb-1">
                        <div className="col-12">
                            <p className="text-muted mb-0">
                                <Validate line={flightDetailLines[index]} header={header}></Validate>
                                <span className="ms-2">{headerText}</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="float-end me-1 text-danger" onClick={() => { removeLine(index) }} />
                                <FontAwesomeIcon icon={faFileImport} className="float-end me-2" onClick={() => { importText(index) }} />
                            </p>
                            <div className={dInputs}>
                                <Form>
                                    <div className="col-8 float-start">
                                        <div className="col-1 float-start text-muted mt-4">
                                            {ac_icon}
                                        </div>
                                        <div className="col-4 float-start pe-1">
                                            <ActionCode_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></ActionCode_EXT>
                                        </div>
                                        <div className="col-3 float-start pe-1">
                                            <Airline_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></Airline_EXT>
                                        </div>
                                        <div className="col-4 float-start pe-1">
                                            <FlightNumber_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></FlightNumber_EXT>
                                        </div>
                                    </div>
                                    <div className="col-1 float-start me-1">
                                            <DateStart_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} seasons={seasons}></DateStart_EXT>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <RawInput index={index}></RawInput>
                    </div>
            }

            if (type == 'turn') {
                line =
                    <div key={index} className="card p-1 mb-1">
                        <p className="text-muted mb-0">
                            <Validate line={flightDetailLines[index]} header={header}></Validate>
                            <span className="ms-2">Turn</span>
                            <FontAwesomeIcon icon={faCircleXmark} className="float-end me-1 text-danger" onClick={() => { removeLine(index) }} />
                            <FontAwesomeIcon icon={faFileImport} className="float-end me-2" onClick={() => { importText(index) }} />
                        </p>
                        <div className={dInputs}>
                            <div className="col-12 clearfix">
                                <div className="col-8 float-start">
                                    <div className="col-1 float-start pe-1">
                                        <FontAwesomeIcon icon={faPlaneArrival} className="ms-1 text-muted mt-4" />
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        <ActionCode_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></ActionCode_EXT>
                                    </div>
                                    <div className="col-3 float-start pe-1">
                                        <TurnAirlineCode_EXT index={index} dep_arr='arr' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnAirlineCode_EXT>
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        <TurnFlightNumber_EXT index={index} dep_arr='arr' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnFlightNumber_EXT>
                                    </div>
                                </div>
                                <div className="col-3 float-start">
                                    <div className="col-4 float-start">
                                        <DateTurn_EXT index={index} tabIndex={0} setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus} safeState={safeState} seasons={seasons} toggleTurnDateType={toggleTurnDateType}></DateTurn_EXT>
                                    </div>


                                </div>
                                <TurnDateRange index={index} messageValues={messageValues}></TurnDateRange>
                            </div>
                            <div className="col-12 clearfix border-top mt-1">
                                <div className="col-8 float-start">

                                    <div className="col-1 float-start pe-1">
                                        <FontAwesomeIcon icon={faPlaneDeparture} className="ms-1 text-muted mt-4" />
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        {'\u00A0'}
                                    </div>
                                    <div className="col-3 float-start pe-1">
                                        <TurnAirlineCode_EXT index={index} dep_arr='dep' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnAirlineCode_EXT>
                                    </div>
                                    <div className="col-4 float-start pe-1">
                                        <TurnFlightNumber_EXT index={index} dep_arr='dep' setMessageValues={setMessageValues} updateParent={updateParent} messageValues={messageValues} setCurrentFocus={setCurrentFocus} currentFocus={currentFocus}></TurnFlightNumber_EXT>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RawInput index={index}></RawInput>
                    </div>
            }

            lines.push(line)
        }

        console.log('flightDetailLines',flightDetailLines)
        if (header.typeOfRequest!='SIR'){
            flightDetailLines.forEach(buildLine)
        }else{
            flightDetailLines.forEach(buildLineSIR)

        }
        return lines
    }

    function DisabledMessage() {
        if (headerOK) { return '' }
        else { return <p className="text-danger">Header invalid</p> }
    }

    return <>
        <div className="col-lg-12 my-auto">
            <Button className="me-1 mb-3" onClick={newArrival} disabled={!headerOK}>Arrival</Button>
            <Button className="me-1 mb-3" onClick={newDeparture} disabled={!headerOK}>Departure</Button>
            <Button className="me-1 mb-3" onClick={newTurn} disabled={!headerOK}>Turn Around</Button>
            <DisabledMessage></DisabledMessage>

        </div>
        <div className="col-lg-12 my-auto">
            <DetailLines></DetailLines>
        </div>
    </>

}

export default FlightDetail