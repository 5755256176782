import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function FlightNumber(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus == 'flightNo') {
        inputRef.current.focus();
    }

    function isValidString(testString) {
        const regex = /^\d{1,6}[A-Za-z]?$|^$/;
        return regex.test(testString);
      }


    function flightNumberEntered(value) {

          console.log('isValidString(value)',)
          if (value != '') {
            setCurrentFocus('flightNo_' + index)
            messageValues['flightDetailLines'][index]['flightNo'] = value
            setMessageValues(messageValues)
            updateParent()
        }
    }

    let hasFocus = currentFocus === 'flightNo_' + index ? true : false

    function checkBlur(){
        console.log('check',messageValues['flightDetailLines'][index]['flightNo'])
        if (!isValidString(messageValues['flightDetailLines'][index]['flightNo'])){
            messageValues['flightDetailLines'][index]['flightNo'] = ''
            setMessageValues(messageValues)
            updateParent()
        }
    }

    return (
        <>
            <p className="text-muted mb-0 small">Flt No</p>
            <Form.Control
                key={Math.random()}
                aria-label="Flight number"
                type="text"
                defaultValue={messageValues['flightDetailLines'][index]['flightNo']}
                onChange={e => { flightNumberEntered(e.target.value) }}
                onBlur={checkBlur}
                onClick={() => { setCurrentFocus('flightNo_' + index) }}
                autoFocus={hasFocus}
                ref={inputRef}
            />
        </>
    );
}

export default FlightNumber