import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function FlightCode(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const safeState = props.safeState
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    let tabIndex = 0
    if (props.tabIndex) {
        tabIndex = props.tabIndex
    }
    function flightCodeSelected(value) {
        messageValues['flightDetailLines'][index]['typeOfFlight'] = value
        setMessageValues(messageValues)
        updateParent()
        setCurrentFocus('typeOfFlight_'+index)
    }

    let hasFocus = currentFocus === 'typeOfFlight_'+index ? true : false

    if (safeState) {
        return (
            <>
                <p className="text-muted mb-0 small">Service Type</p>
                <Form.Select 
                    aria-label="Service Type" 
                    defaultValue={messageValues['flightDetailLines'][index]['typeOfFlight']} 
                    onChange={e => { flightCodeSelected(e.target.value) }} 
                    className="float-start" 
                    tabIndex={tabIndex}
                    autoFocus={hasFocus}
                    >
                    <option></option>
                    <option value="C">C - Charter – Passenger only</option>
                    <option value="J">J - Scheduled – Passenger Normal Service</option>
                    <option value="P">P - Positioning Flights – Non Revenue (ferry/delivery/demo)</option>
                    <option value="T">T - Technical Test</option>
                </Form.Select>
            </>
        );
    } else {
        return (
            <>
                <p className="text-muted mb-0 small">Service Type</p>
                <Form.Select 
                    aria-label="Service Type" 
                    defaultValue={messageValues['flightDetailLines'][index]['typeOfFlight']} 
                    onChange={e => { flightCodeSelected(e.target.value) }} 
                    className="float-start" 
                    tabIndex={tabIndex}
                    autoFocus={hasFocus}

                    >
                    <option></option>
                    <option value="A">A - Additional Cargo/Mail</option>
                    <option value="C">C - Charter – Passenger only</option>
                    <option value="E">E - Special VIP Flight (FAA/Government)</option>
                    <option value="F">F - Scheduled – Cargo and/or Mail</option>
                    <option value="G">G - Additional Flights – Passenger Normal Service</option>
                    <option value="H">H - Charter – Cargo and/or Mail</option>
                    <option value="I">I - Ambulance Flight</option>
                    <option value="J">J - Scheduled – Passenger Normal Service</option>
                    <option value="K">K - Training Flights</option>
                    <option value="L">L - Football European Championship 2024</option>
                    {/* <option value="L">L - Passenger and Cargo and/or Mail</option> */}
                    <option value="M">M - Mail Service</option>
                    <option value="O">O - Charter requiring special handling (e.g. migrants, immigrants)</option>
                    <option value="P">P - Positioning Flights – Non Revenue (ferry/delivery/demo)</option>
                    <option value="T">T - Technical Test</option>
                    <option value="W">W - Military</option>
                    <option value="X">X - Technical Stop</option>
                </Form.Select>
            </>
        );

    }

}

export default FlightCode