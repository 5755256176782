import React, { useRef } from "react";
import Form from 'react-bootstrap/Form';

function DestinationAirport(props) {
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    if (inputRef.current && currentFocus =='flightNo') {
        inputRef.current.focus();
    }
    
    function flightNumberEntered(value) {
        if (value != ''){
            setCurrentFocus('originAirportIataCode_'+index)
            messageValues['flightDetailLines'][index]['originAirportIataCode'] = value
            setMessageValues(messageValues)
            updateParent()

        }
    }

    let hasFocus = currentFocus === 'originAirportIataCode_'+index ? true : false

    return (
        <>
            <p className="text-muted mb-0 small">Origin</p>
            <Form.Control 
                key={Math.random()} 
                aria-label="Origin" 
                type="text" 
                defaultValue={messageValues['flightDetailLines'][index]['originAirportIataCode']} 
                onChange={e => { flightNumberEntered(e.target.value) }} 
                onClick={() => { setCurrentFocus('originAirportIataCode_'+index) }}
                autoFocus={hasFocus}
                ref={inputRef}

                />
        </>
    );
}

export default DestinationAirport