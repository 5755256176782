import React, { useRef, useEffect } from "react";
import Form from 'react-bootstrap/Form';

function ActionCode(props) {
    console.log(props)
    const index = props.index;
    const messageValues = props.messageValues;
    const setMessageValues = props.setMessageValues;
    const updateParent = props.updateParent;
    const setCurrentFocus = props.setCurrentFocus
    const currentFocus = props.currentFocus
    const inputRef = useRef(null);

    useEffect(()=>{
        if (messageValues['header']['typeOfRequest'] == 'SIR') {
            console.log('CHECKING',messageValues['flightDetailLines'][index]['actionCode'])

            if (messageValues['flightDetailLines'][index]['actionCode']!='Q'){
                console.log('RUNNING')
                const newMessageValues = { ...messageValues };
                newMessageValues['flightDetailLines'][index]['actionCode'] = 'Q';
                setMessageValues(newMessageValues);
                setCurrentFocus('action_' + index)
                updateParent();
    
            }

    
        }
    },[messageValues])

    // console.log('find inputRef',inputRef)
    if (inputRef.current && currentFocus == 'action_' + index) {
        inputRef.current.focus();
    }

    function actionSelected(e) {
        const newMessageValues = { ...messageValues };
        newMessageValues['flightDetailLines'][index]['actionCode'] = e.target.value;
        setMessageValues(newMessageValues);
        updateParent();
        setCurrentFocus('action_' + index)
    }

    let hasFocus = currentFocus === 'action_' + index ? true : false

    if (messageValues['header']['typeOfRequest'] == 'SIR') {
        return <>
            <div>
                <p className="text-muted mb-0 small">Action</p>
                <Form.Select
                    aria-label="Action Codes"
                    value={messageValues['flightDetailLines'][index]['actionCode']}
                    onChange={e => actionSelected(e)}
                    onClick={() => { setCurrentFocus('action_' + index) }}
                    className="float-start"
                    key={Math.random}
                    ref={inputRef}
                    autoFocus={hasFocus}
                >
                    <option value=""></option>
                    <option value="Q">Q - Query</option>
                </Form.Select>
            </div>
        </>

    }

    return (
        <div>
            <p className="text-muted mb-0 small">Action</p>
            <Form.Select
                aria-label="Action Codes"
                value={messageValues['flightDetailLines'][index]['actionCode']}
                onChange={e => actionSelected(e)}
                onClick={() => { setCurrentFocus('action_' + index) }}
                className="float-start"
                key={Math.random}
                ref={inputRef}
                autoFocus={hasFocus}
            >
                <option></option>
                <option value="N">N - New schedule</option>
                <option value="D">D - Delete schedule</option>
                <option value="C">C - Schedule to be changed</option>
                <option value="R">R - Revised schedule</option>
                <option value="A">A - Accept an offer</option>
                <option value="P">P - Accept an offer with pending request time</option>
                <option value="Z">Z - Decline an offer</option>
            </Form.Select>
        </div>
    );
}

export default ActionCode;
