import React, {
    useEffect,
    useState,
} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

const useCopyToClipboard = text => {
    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        const success = document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
        return success;
    };

    const [copied, setCopied] = React.useState(false);

    const copy = React.useCallback(() => {
        if (!copied) setCopied(copyToClipboard(text));
    }, [text]);
    React.useEffect(() => () => setCopied(false), [text]);

    return [copied, copy];
};

function Output(props) {
    console.log('props', props)

    function HeaderOutput() {
        return <>
            <code>{props.messageValues.header.typeOfRequest}{'\n'}</code><br></br>
            <code>{props.messageValues.header.userInitials}{'\n'}</code><br></br>
            <code>{props.messageValues.header.season}</code><br></br>
            <code>{props.messageValues.header.date}</code><br></br>
            <code>{props.messageValues.header.airport}</code><br></br>
        </>
    }

    function FootnoteOutput() {
        // console.log(props.messageValues)
        return <>
            <code>SI {props.messageValues.footer.SI}</code><br></br>
            <code>GI {props.messageValues.footer.GI}</code><br></br>
        </>
    }

    function FlightDetailOutput() {

        let lines = []

        function buildDays(value) {
            let daysArray = value.weekdays
            let string = ''
            function addDay(day) {
                string = string + day
            }
            daysArray.forEach(addDay)
            return string
        }

        function buildLine(value, index) {
            // console.log(value)
            let line

            let days = buildDays(value)

            if (value.showRawInput) {
                line = <>
                    <code>{value.rawInputValue}</code><br></br>
                </>

            } else {
                // console.log(value)
                if (value.type == 'arrival') {
                    if (!value.showDateSpan) {
                        line = <>
                            <code>{value.actionCode}{value.airline}{value.flightNo} {value.periodStart} {value.aircraftSeats}{value.iataAircraftCode} {value.originAirportIataCode}{value.lastAirportIataCode}{value.arrivalTimeUTC} {value.typeOfFlight}</code><br></br>
                        </>

                    }
                    else {

                        line = <>
                            <code>{value.actionCode}{value.airline}{value.flightNo} {value.periodStart}{value.periodEnd} {days} {value.aircraftSeats}{value.iataAircraftCode} {value.originAirportIataCode}{value.arrivalTimeUTC} {value.typeOfFlight}</code><br></br>
                        </>
                    }
                }
                if (value.type == 'departure') {
                    if (!value.showDateSpan) {
                        line = <>
                            <code>{value.actionCode} {value.airline}{value.flightNo} {value.periodStart} {value.aircraftSeats}{value.iataAircraftCode} {value.departureTimeUTC}{value.nextAirportIataCode}{value.destinationAirportIataCode} {value.typeOfFlight}</code><br></br>
                        </>

                    } else {
                        line = <>
                            <code>{value.actionCode} {value.airline}{value.flightNo} {value.periodStart}{value.periodEnd} {days} {value.aircraftSeats}{value.iataAircraftCode} {value.departureTimeUTC}{value.nextAirportIataCode}{value.destinationAirportIataCode} {value.typeOfFlight}</code><br></br>
                        </>

                    }
                }
                if (value.type == 'turn') {
                    if (value.turnDateType == 'single')
                        line = <>
                            <code>{value.actionCode}{value.arrAirline}{value.arrFlightNo} {value.depAirline}{value.depFlightNo} {value.turnDate} {value.aircraftSeats}{value.iataAircraftCode} {value.originAirportIataCode}{value.arrivalTimeUTC} {value.departureTimeUTC}{value.Oval}{value.destinationAirportIataCode} {value.arrTypeOfFlight}{value.depTypeOfFlight}</code><br></br>
                        </>
                    }
                    if (value.turnDateType == 'range'){
                        line = <>
                            <code>{value.actionCode}{value.airline} {value.arrFlightNo} {value.depAirline}{value.depFlightNo} {value.periodStart}{value.periodEnd} {days} {value.aircraftSeats}{value.iataAircraftCode} {value.originAirportIataCode}{value.arrivalTimeUTC} {value.departureTimeUTC}{value.destinationAirportIataCode} {value.arrTypeOfFlight}{value.depTypeOfFlight}</code><br></br>
                        </>
                    }
                
            }

            lines.push(line)
        }

        let detailLines = props.messageValues.flightDetailLines
        // console.log('detailLines', detailLines)
        detailLines.forEach(buildLine)

        return lines

    }

    function copyText() {
        // Get the text field
        var copyText = document.getElementById("output").innerText;
        navigator.clipboard.writeText(copyText);
    }

    return <>
        <div className="col-lg-12 my-auto" id="output">
            <HeaderOutput messageValues={props.messageValues}></HeaderOutput>
            <FlightDetailOutput></FlightDetailOutput>
            <FootnoteOutput></FootnoteOutput>
        </div>
        <button className="btn btn-outline-primary mt-3" onClick={copyText}><FontAwesomeIcon icon={faCopy} /> Copy</button>
    </>

}

export default Output